import React from 'react';
import Layout from './src/templates/Layout';
import { GlobalContextProvider } from './src/context';
// import SSRProvider from "react-bootstrap/SSRProvider";

export const onRouteUpdate = ({ location }) => {
    if (typeof window === 'undefined') return;

    if (location.hash) {
        setTimeout(() => {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 800);
    } else {
        window.scrollTo(0, 0);
    }
};

export function wrapPageElement({ element, props }) {
    return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
    return <GlobalContextProvider>{element}</GlobalContextProvider>;
}

// const React = require("react")
// const {GlobalContextProvider} = require('./src/context/GlobalContextProvider')

// exports.wrapRootElement = ({ element }) => {
//   return (
//     <GlobalContextProvider>{element}</GlobalContextProvider>
//   )
// }
